exports.env = function () {
  return {
    // 开发环境变量
    // 修改BASE_API需要重跑
    development: {
      BASE_API: 'http://182.92.208.167:8080',
      IMAGE_PREFIX: 'http://182.92.208.167:81/',
      WEBSOCKET: 'ws://182.92.208.167:8080/websocket',

      // BASE_API: 'http://192.168.1.168:8080',
      // WEBSOCKET: 'ws://182.92.208.167:8080/websocket',
      // IMAGE_PREFIX: 'http://182.92.208.167:81/'
    },
    // 生产环境变量
    production: {
      BASE_API: 'http://182.92.208.167:8080',
      IMAGE_PREFIX: 'http://182.92.208.167:81/',
      WEBSOCKET: 'ws://182.92.208.167:8080/websocket',
    }
  }
}
