import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
// 路由注入
import '@utils/router'
// element-plus
import '@assets/styles/theme/element-plus.scss'
// style
import '@assets/styles/index.scss'

// interceptor - 路由拦截器
import '@/router/interceptor'
// 自定义指令
import directive from '@/directive'
import VueGridLayout from 'vue-grid-layout'
// icon图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

(window as any)._AMapSecurityConfig = {
  securityJsCode: '2f02220adf564cf99a747fc9f3904e73'
}

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// components.install(app)
directive.install(app)
// app.use(store).use(router).mount('#app')

app.use(store).use(router).use(VueGridLayout).mount('#app')
